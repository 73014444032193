// extracted by mini-css-extract-plugin
export var Container = "V_c7";
export var aniBgLoading = "V_f";
export var aniSpin = "V_d";
export var gridColD1 = "V_db";
export var gridColD10 = "V_dm";
export var gridColD11 = "V_dn";
export var gridColD12 = "V_dp";
export var gridColD2 = "V_dc";
export var gridColD3 = "V_dd";
export var gridColD4 = "V_df";
export var gridColD5 = "V_dg";
export var gridColD6 = "V_dh";
export var gridColD7 = "V_dj";
export var gridColD8 = "V_dk";
export var gridColD9 = "V_dl";
export var gridColM1 = "V_dq";
export var gridColM2 = "V_dr";
export var gridRowD = "V_c8";
export var gridRowM = "V_c9";